import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/grid'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { colors } from '@styles/colors'
import { Link } from 'gatsby'

export const PostWrapFlex = styled(Flex)`
  .project-icon {
    max-width: 90%;
    height: 10rem;
    display: inline-flex;
    ${breakpoint.gt('md')`height: 16rem;`}

    img {
      object-fit: contain;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(0.99);
      }
    }
  }

    .post-item-previous, .post-item-next {
      h6 {

        color: ${colors.light};
      }
      position: relative;

      span {
        display: inline-block;
        margin-right: 1rem;

      }
    }

  .post-item-previous {
      span {
        margin-left: 0;
        ${breakpoint.lt('md')`width: 68%;`}
      }
  }

  .post-item-next {
      span {
        margin-right: 1rem;
        ${breakpoint.lt('md')`width: 68%;`}
      }

      a {
            margin-left: auto;
      }

  }

  &.post-layout_1 {


    .post-item-previous {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: start;
    }

    .post-item-next {
      width: 100%%;
      text-align: right;
      display: flex;
      justify-content: end;
    }
  }

  &.post-layout_2 {
    .post-item-previous {
      width: 50%;
      text-align: left;
          display: flex;
    justify-content: start;
    }

    .post-item-next {
      width: 50%;
      text-align: right;
      justify-self: end;

    }
  }
}
`

export const PostLinkBox = styled(Box)`
  cursor: pointer;

  h6 {
    color: #6b6a6a;
  }
`

export const StyledPostLink = styled(Link)`
  display: block;
  span {
    margin-top: 0.5rem;
  }

  ${breakpoint.gt('md')`width: 25rem;`}
`
